import { render, staticRenderFns } from "./ResearchDotPlot.vue?vue&type=template&id=343d8f34&scoped=true"
import script from "./ResearchDotPlot.vue?vue&type=script&lang=js"
export * from "./ResearchDotPlot.vue?vue&type=script&lang=js"
import style0 from "./ResearchDotPlot.vue?vue&type=style&index=0&id=343d8f34&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "343d8f34",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ec2-user/.jenkins/workspace/MATKP - Dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('343d8f34')) {
      api.createRecord('343d8f34', component.options)
    } else {
      api.reload('343d8f34', component.options)
    }
    module.hot.accept("./ResearchDotPlot.vue?vue&type=template&id=343d8f34&scoped=true", function () {
      api.rerender('343d8f34', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/researchPortal/singleCellBrowser/ResearchDotPlot.vue"
export default component.exports