var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "mat-header f-row spread-out align-v-center",
      class: _vm.glass ? "glass" : "",
    },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "f-row menu" },
        _vm._l(_vm.menuItems, function (item) {
          return _c(
            "div",
            {
              staticClass: "menu-item-wrapper",
              class: { active: _vm.isActive(item.path) },
            },
            [
              _c(
                "a",
                {
                  staticClass: "menu-item",
                  attrs: { href: item.path || null },
                },
                [_vm._v(" " + _vm._s(item.label) + " ")]
              ),
              item.subMenuItems
                ? _c(
                    "div",
                    { staticClass: "submenu" },
                    _vm._l(item.subMenuItems, function (subItem) {
                      return _c(
                        "a",
                        {
                          staticClass: "submenu-item",
                          class: { active: _vm.isActive(subItem.path) },
                          attrs: {
                            href: subItem.path || null,
                            "data-whatever": _vm
                              .isActive(subItem.path)
                              .toString(),
                          },
                        },
                        [_vm._v(" " + _vm._s(subItem.label) + " ")]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "a",
      { staticClass: "logo f-row align-v-center", attrs: { href: "/" } },
      [
        _c("img", {
          attrs: {
            src: "https://hugeampkpncms.org/sites/default/files/users/user32/matkp/matkplll.png",
          },
        }),
        _c("span", { staticStyle: { "font-weight": "600" } }, [
          _vm._v("MAT"),
          _c("span", { staticStyle: { "font-weight": "300" } }, [_vm._v("KP")]),
          _c("span", { staticClass: "tagline" }, [
            _vm._v("The place for fat."),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }