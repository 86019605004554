var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-input-group",
    { attrs: { size: "sm" } },
    [
      _c("gene-selectpicker", {
        ref: "srch",
        staticStyle: { width: "100%" },
        on: { onGeneChange: (gene) => _vm.searchGene(gene) },
        model: {
          value: _vm.searchQuery,
          callback: function ($$v) {
            _vm.searchQuery = $$v
          },
          expression: "searchQuery",
        },
      }),
      _c(
        "b-button",
        {
          staticClass: "button-lock-right",
          on: {
            click: function ($event) {
              return _vm.searchGene()
            },
          },
        },
        [_vm._v(" Search ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }