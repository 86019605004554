var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "mat-footer f-row align-h-center",
      staticStyle: { "font-size": "14px", "z-index": "100", padding: "50px" },
    },
    [
      _c(
        "div",
        { staticClass: "menu" },
        _vm._l(_vm.menuItems, function (item) {
          return _c("div", { staticClass: "menu-item-wrapper" }, [
            _c(
              "a",
              {
                staticClass: "menu-item",
                attrs: {
                  href: item.path || null,
                  target: item.external ? "_blank" : null,
                },
              },
              [_vm._v(" " + _vm._s(item.label) + " ")]
            ),
            item.subMenuItems
              ? _c(
                  "div",
                  { staticClass: "submenu" },
                  _vm._l(item.subMenuItems, function (subItem) {
                    return _c(
                      "a",
                      {
                        staticClass: "submenu-item",
                        attrs: {
                          href: subItem.path || null,
                          target: subItem.external ? "_blank" : null,
                        },
                      },
                      [_vm._v(" " + _vm._s(subItem.label) + " ")]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }