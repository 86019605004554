var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "f-col", staticStyle: { width: "800px", gap: "10px" } },
    [
      _c("div", { staticClass: "f-row", staticStyle: { gap: "50px" } }, [
        _c("div", { staticClass: "f-col", staticStyle: { gap: "20px" } }, [
          _c(
            "div",
            { staticStyle: { "font-size": "18px", "font-weight": "bold" } },
            [_vm._v("Species")]
          ),
          _c("div", { style: `width:${_vm.anatomyWidth}px` }, [
            _c("select", { on: { change: _vm.selectSpecies } }, [
              _c("option", { attrs: { value: "Homo sapiens", selected: "" } }, [
                _vm._v("Homo sapiens"),
              ]),
              _c("option", { attrs: { value: "Mus musculus" } }, [
                _vm._v("Mus musculus"),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "f-col anatomy", staticStyle: { gap: "10px" } },
            [
              _c(
                "div",
                {
                  staticClass: "anatomy-item",
                  style: `width:${_vm.anatomyWidth}px`,
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "anatomy-points",
                      staticStyle: {
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        "z-index": "1",
                      },
                    },
                    [
                      _vm._l(_vm.depots[_vm.selectedSpecies], function (depot) {
                        return _vm._l(depot.pos, function (point) {
                          return depot.pos.length > 0 &&
                            depot.pos[0].length === 2
                            ? _c("div", {
                                staticClass: "depot-point on",
                                staticStyle: {
                                  position: "absolute",
                                  width: "15px",
                                  height: "15px",
                                  transform: "translate(-10%,-10%)",
                                  "border-radius": "50%",
                                  background: "gold",
                                },
                                style: `left:${
                                  point[0] *
                                  _vm.anatomyImages[_vm.selectedSpecies].scale
                                }px; top:${
                                  point[1] *
                                  _vm.anatomyImages[_vm.selectedSpecies].scale
                                }px`,
                                attrs: {
                                  "data-depot": `${depot.ontology}`,
                                  "data-category": depot.category,
                                },
                              })
                            : _vm._e()
                        })
                      }),
                    ],
                    2
                  ),
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "50%",
                          overflow: "hidden",
                          right: "0",
                        },
                      },
                      [
                        _c("img", {
                          style: `width:${_vm.anatomyWidth}px`,
                          attrs: {
                            src: _vm.anatomyImages[_vm.selectedSpecies].male,
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          top: "0",
                          right: "0",
                          transform: "scaleX(-1)",
                          width: "50%",
                          overflow: "hidden",
                          "border-right": "1px dashed black",
                        },
                      },
                      [
                        _c("img", {
                          style: `width:${_vm.anatomyWidth}px`,
                          attrs: {
                            src: _vm.anatomyImages[_vm.selectedSpecies].female,
                          },
                        }),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "center",
                "font-size": "12px",
                height: "40px",
              },
              style: `width:${_vm.anatomyWidth}px`,
            },
            [_vm._v(_vm._s(_vm.depotDescription))]
          ),
        ]),
        _c("div", { staticClass: "f-col", staticStyle: { gap: "15px" } }, [
          _c(
            "div",
            { staticStyle: { "font-size": "18px", "font-weight": "bold" } },
            [_vm._v("Depots")]
          ),
          _c(
            "div",
            {
              staticClass: "depot-items",
              staticStyle: { "max-height": "400px" },
            },
            [
              _c(
                "div",
                { staticClass: "f-row", staticStyle: { gap: "20px" } },
                [
                  _vm._l(_vm.depotsByCategory, function (depots, category) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "f-col",
                          staticStyle: { gap: "5px", width: "150px" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "depot-category",
                              staticStyle: {
                                "font-weight": "bold",
                                "text-transform": "capitalize",
                                padding: "5px 0",
                              },
                              on: {
                                mouseover: function ($event) {
                                  return _vm.highlightDepotCategory(category)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(category) + " ")]
                          ),
                          _vm._l(depots, function (depot) {
                            return _c(
                              "div",
                              {
                                staticClass: "depot-item f-row spread-out",
                                style: `cursor:${
                                  depot.count && depot.count > 0
                                    ? "pointer"
                                    : "default"
                                }`,
                                on: {
                                  mouseover: function ($event) {
                                    return _vm.highlightDepot($event, depot)
                                  },
                                  click: function ($event) {
                                    return _vm.goToDatasets($event, depot)
                                  },
                                },
                              },
                              [
                                _c("div", [_vm._v(_vm._s(depot.name))]),
                                depot.count > 0
                                  ? _c("div", [
                                      _vm._v("(" + _vm._s(depot.count) + ")"),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }